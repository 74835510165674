var generic = generic || {};
var site = site || {};

generic.endeca = generic.endeca || {
    catalog: {},
    result: {},
    results: {},
    resultsgroup: {},
    mixins: {},
    instances: {},
    generic: {
        Class: generic.Class || {},
        env: generic.env || {},
        rb: generic.rb || {},
        template: generic.template || {}
    },
    helpers: {
        array: {
            toInt: function( array ) {
                for ( var i = 0; i < array.length; i++ ) {
                    array[i] = parseInt( array[i] );
                }
                return array;
            },
            unique: function( array ) {
                var o = {}, a = [];
                for ( var i = 0; i < array.length; i++ ) {
                    if ( typeof o[array[i]] == 'undefined' ) { a.push( array[i] ); }
                    o[array[i]] = 1;
                }
                return a;
            },
            remove: function( array, valuesToRemove ) {
                var newArray;
                var valuesToRemove = jQuery.isArray( valuesToRemove ) ? valuesToRemove : [valuesToRemove];
                return jQuery.grep( array, function( value ) {
                    return jQuery.inArray( value, valuesToRemove ) == -1 ;
                });
            }
        }, 
        func: {
            bind: function() { 
                var _func = arguments[0] || null; 
                var _obj = arguments[1] || this; 
                var _args = jQuery.grep(arguments, function(v, i) { 
                    return i > 1; 
                }); 
            
                return function() { 
                    return _func.apply(_obj, _args); 
                }; 
            }
        },
        string: {
            toQueryParams: function( string, separator ) {
            	var string = string || '';
            	var separator = separator || '&';
            	var paramsList = string.substring(string.indexOf('?')+1).split('#')[0].split(separator || '&'), params = {}, i, key, value, pair;
            	for (i=0; i<paramsList.length; i++) {
            		pair = paramsList[i].split('=');
            		key = decodeURIComponent(pair[0]);
            		value = (pair[1])?decodeURIComponent(pair[1]):'';
            		if (params[key]) {
            			if (typeof params[key] == "string") { params[key] = [params[key]]; }
            			params[key].push(value);
            		} else { params[key] = value; }
            	}
            	return params;
            }  
        },
        obj: {
            first: function( obj ) {
                for ( var i in obj ) { return obj[i]; }
            },
            slice: function( obj, array ) {
                var h = {};
                for ( var i = 0; i < array.length; i++ ) {
                    if ( typeof obj[array[i]] != 'undefined' ) {
                        h[array[i]] = obj[array[i]];
                    }
                }
                return h;
            }
        }
    }
};

site.endeca = generic.endeca;



var rb = rb || {};

/**
* This method provides access to resource bundle values that have been 
* written to the HTML in JSON format. The file that outputs these values
* must be included in the .html as a script tag with the desired RB name
* as a query string paramter.
* 
* @class ResourceBundle
* @namespace generic.rb
* 
* @memberOf generic
* @methodOf generic
* @requires generic.Hash (minimal functional replication of Prototype Hash Class)
* 
* @example Inline data
* 
*    <script src="/js/shared/v2/internal/resource.tmpl?rb=account"></script>
* 
* @example Script retrival of data values
* 
*    var myBundle = generic.rb("account");
*    myBundle.get("err_please_sign_in");
*    
* 
* @param {String} rbGroupName name of resource bundle needed
* 
* @returns An object that provides the main get method
* 
*/
generic.endeca.generic.rb = function (rbGroupName) {

  var findResourceBundle = function (groupName) {

    if (groupName && rb) {

      var rbName = groupName;
      var rbObj = rb[rbName];
      if (rbObj) {
        return rbObj;
      } else {
        return {};
      }
    } else {
      return {};
    }

  };

  var resourceBundle = findResourceBundle(rbGroupName);

  var returnObj = {
    /**
    * @public This method will return the value for the requested Resource Bundle key.
    * If the key is not found, the key name will be returned.
    * 
    * @param {String} keyName key of desired Resource Bundle value
    */
    get: function (keyName) {
      if (typeof (keyName) != "string") {
        return null;
      }
      var val = resourceBundle[keyName];
      if (val) {
        return val;
      } else {
        return keyName;
      }
    }
  };

  return returnObj;

};


/**
 * Minimal Native Version of Prototype Class
 * 
 * @deprecated Jquery extend method has options for deep copy extensions
 * 
 * @class Class
 * @namespace generic.Class
 * 
 */

generic.endeca.generic.Class = { // Uppercase 'Class', avoid IE errors

  fn: function (src, props) {

    var tgt, prxy, z, fnTest = /xyz/.test(function () { xyz; }) ? /\b_super\b/ : /.*/;

    tgt = function () { // New Constructor
      // Initialize Method is a Requirement of Class
      // With the inclusion of the _super method, initialize in the superclass should only be called on demand
      /*if(tgt.superclass&&tgt.superclass.hasOwnProperty("initialize")){
          tgt.superclass.initialize.apply(this,arguments);
      }*/
      if (tgt.prototype.initialize) {
        tgt.prototype.initialize.apply(this, arguments);
      }
    };

    // Preserve Classical Inheritance using Proxy Middle
    src = src || Object;
    prxy = function () { }; /* Potentially define "Class" here */
    prxy.prototype = src.prototype;
    tgt.prototype = new prxy();
    tgt.superclass = src.prototype;
    tgt.prototype.constructor = tgt;

    // give new class 'own' copies of props and add _super method to call superclass' corresponding method
    for (z in props) {
      if (typeof props[z] == "function" && typeof tgt.superclass[z] == "function" && fnTest.test(props[z])) {
        tgt.prototype[z] = (function (z, fn) {
          return function () {
            this._super = tgt.superclass[z];
            var ret = fn.apply(this, arguments);
            return ret;
          };
        })(z, props[z])
      } else {
        tgt.prototype[z] = props[z];
      }
      /*if(props.hasOwnProperty(z)){tgt.prototype[z]=props[z];}*/
    }

    return tgt;

  },
  create: function () {

    var len = arguments.length, args = Array.prototype.slice.call(arguments), fn = generic.endeca.generic.Class.fn;

    if (len == 2) { tgt = generic.endeca.generic.Class.fn(args[0], args[1]); }
    else if (len == 1) { tgt = generic.endeca.generic.Class.fn(null, args[0]); }
    else { tgt = function () { }; /* return empty constructor */ }

    return tgt; // return constructor that stacks named Class w/ object-literal, works with instanceof

  }, // End Create Method    
  mixin: function (baseClass, mixin) {
    var newClass = baseClass;
    if (mixin && mixin.length) {
      for (var i = 0; i < mixin.length; i++) {
        newClass = generic.endeca.generic.Class.mixin(newClass, mixin[i]);
      }
    } else {
      if (mixin) { newClass = generic.endeca.generic.Class.create(newClass, mixin); }
    }
    return newClass;
  }
};


generic.endeca.generic.env = {
  isIE: !!(typeof (ActiveXObject) == 'function'),
  isIE6: !!(!!(typeof (ActiveXObject) == 'function') && (/MSIE\s6\.0/.test(navigator.appVersion))),
  isFF: !!(typeof (navigator.product) != 'undefined' && navigator.product == 'Gecko' && !((document.childNodes) && (!navigator.taintEnabled)) && /firefox/.test(navigator.userAgent.toLowerCase())),
  isFF2: !!(typeof (navigator.product) != 'undefined' && navigator.product == 'Gecko' && !((document.childNodes) && (!navigator.taintEnabled)) && navigator.userAgent.toLowerCase().split(' firefox/')[1] && navigator.userAgent.toLowerCase().split(' firefox/')[1].split('.')[0] == '2'),
  isFF3: !!(typeof (navigator.product) != 'undefined' && navigator.product == 'Gecko' && !((document.childNodes) && (!navigator.taintEnabled)) && navigator.userAgent.toLowerCase().split(' firefox/')[1] && navigator.userAgent.toLowerCase().split(' firefox/')[1].split('.')[0] == '3'),
  isMac: !!(/macppc|macintel/.test(navigator.platform.toLowerCase())),
  isSafari: !!(/Safari/.test(navigator.userAgent)),

  domain: window.location.protocol + "//" + window.location.hostname,

  parsedQuery: function () {

    var query = window.location.search.toString().split('?')[1] || '';
    var main = function () {
      var params = {};
      var pairs = query.split('&');

      if (!query) {
        return;
      }

      if (query.indexOf('?') > -1) {
        query = query.split('?')[1];
      }
      pairs.forEach(function (pair) {
        var tempPair = pair.split('=');

        params[tempPair[0]] = decodeURIComponent(tempPair[1] || '');
      });
      return params;
    };
    var parameters = main() || {};

    return parameters;
  },
  query: function (key) {
    var result = generic.endeca.generic.env.parsedQuery()[key] || null;
    return result;
  }
};


/**
 * Template.js
 * 
 * @memberOf generic
 * 
 * @class TemplateSingleton
 * @namespace generic.template
 * 
 * @requires object literal with parameters
 * 
 * @param path attribute as a literal key is required
 * @example "/templates/cart-overlay.tmpl",
 * 
 * @param {string} templateString takes first priority
 * @example templateString:'#{product.url} some-page-markup-with-#{product.url}'
 * 
 * @param {boolean} forceReload
 * 
 * @param {function} callback
 * @example
 * 
 * callback:function(html) {
 *    // Front-End Resolution
 *    jQuery('#container').html(html);
 * }
 * 
 * @param {object} query object hash with object-literals, array-literals that can be nested
 * @example example structure
 * query: {
 *    a:'',b:{},c:[],d:{[]} // keys rooted to named parent if object or array-objects are nested
 * }
 * 
 * @param {object} Hash of string-literals with string values that map to the template
 * @example
 * 
 * object: {
 *    'product.PROD_RGN_NAME':'replacement',
 *    SOME_VAR:'replacement'
 * }
 * 
 * @example Usage
 * 
 * generic.template.get({
 *    path:"/some/path/to/template.tmpl",
 *    ...
 * });
 * 
 * @param {HTML} (optional) Markup based inline template
 * @required The path attribute must match the path key passed to the get method.
 * 
 * @example Inline Template Example
 * 
 * <!-- -------------------------- Inline Template ------------------------------ -->
 * 
 * <script type="text/html" class="inline-template" path="templates/foo.tmpl">"
 *         <div>#{FIRST_NAME}</div>
 *         <div>#{SECOND_NAME}</div>
 * </script>
 * 
 * Inline Templates : Valid inline template via script tag in this format, aside
 * from the templateString parameter, will be the first candidate for the template,
 * then the cache, then ajax.
 * 
 * 
 * @returns {object} An object that refers to a singleton which provides
 * the primary get api method.
 * 
 */

generic.endeca.generic.template = (function () {

  var that = {};
  var templateClassName = ".inline-template";
  var templates = {};

  // mustache stuff
  var translations;
  var partials;
  // end mustache stuff

  /**
   * This method loads a pre-interpolation template into the object's internal cache. This cache is checked before attempting to pull the template from the DOM or load it via Ajax.
   * @param (String) key The name that is used to retrieve the template from the internal cache. Typically mathces the path for Ajax-loaded templates.
   * @param (String) html The non-interpoltaed content of the template.
   * @returns (Strin) the HTML that was originally passed in
   * @private
   */
  var setInternalTemplate = function (key, html) {
    templates[key] = html;
    return html;
  };

  var getInternalTemplate = function (key) {
    var template = templates[key];

    if (!template && site.templates && site.templates[key]) {
      templates[key] = site.templates[key].content;
      template = templates[key];
    }

    return template;
  };

  var returnTemplate = function (args) {
    var html = args.template;

    html = interpolate({ template: html, recurseParams: { object: args.object, rb: args.rb }, Lre: /\[jsInclude\]/i, Rre: /\[\/jsInclude\]/i });

    if (typeof args.rb === "object") { html = interpolate({ template: html, obj: args.rb, Lre: /\[rb\]/, Rre: /\[\/rb\]/ }); }

    //if ( typeof args.object === "object" ) { html = interpolate({ template: html, obj: args.object }); }

    if (typeof args.object === "object") {
      try {
        if (html.match(/\{\{.*\[/) && html.match(/\].*\}\}/)) {
          throw "generic.template: template expects array notation, defaulting to non-mustache rendering";
        }

        translations = translations || {
          globals: {
            t: site.translations || {},
            variables: Drupal.settings.common || {}
          }
        };

        var obj = $.extend({}, args.object, translations);

        html = typeof Mustache.to_html === 'function' ? Mustache.to_html(html, obj, templates) : Mustache.render(html, obj, templates);
      } catch (e) {
        console.log(e);
        html = interpolate({ template: html, obj: args.object });
      }
    }

    return html;

  };

  var interpolate = function (args) {
    var args = args || {};
    // we have to split after {{{ first in case the template has a html render type
    args.Lre = args.Lre || /\{\{\{|\{\{/;
    args.Rre = args.Rre || /\}\}\}|\}\}/;

    var obj = args.obj || args.rb || {};
    var tmpl = args.template || "",
      recurseParams = args.recurseParams || null,
      Lre = new RegExp(args.Lre),
      Rre = new RegExp(args.Rre),
      tmplA = [],
      temp, lft, rght;

    tmplA = tmpl.replace(/[\r\t\n]/g, " ").split(Lre); // array of (.+?)} with '}' marking key vs rest of doc

    var returnString = "";
    for (var x = 0; x < tmplA.length; x++) {
      var chunk = tmplA[x];
      var splitChunk = chunk.split(Rre);

      if (typeof splitChunk[1] !== "undefined") { // close tag is found
        var valueToInsert = "";

        if (recurseParams) {
          recurseParams['path'] = splitChunk[0];
          valueToInsert = that.get(recurseParams);
        } else {

          // First check array notation for property names with spaces
          // Then check object notation for deep reference
          valueToInsert = obj['" + splitChunk[0] + "'] || obj[splitChunk[0]];
          if (typeof valueToInsert === "undefined" || valueToInsert === null) {
            valueToInsert = '';
            var tempChunk = splitChunk[0] && typeof splitChunk[0] !== "undefined" ? splitChunk[0] : '';
            var separator = tempChunk.match(/\[\d\]/) || tempChunk.match('[.]');
            var separatorText = separator !== null ? separator[0] : ' ';
            var tempSplit = tempChunk.split(separatorText);
            if (tempSplit.length > 1) {
              var arrayName = tempSplit[0];
              var searchKey = tempSplit[1];
              if (separatorText === '.') {
                valueToInsert = obj[arrayName][searchKey] && obj[arrayName][searchKey] !== 'undefined' ? obj[arrayName][searchKey] : '';
              }
              else {
                searchKey = searchKey.substring(1);
                var indexNum = separatorText.match(/\d+/g);
                var index = indexNum !== null ? parseInt(indexNum[0], 10) : 0;
                valueToInsert = obj[arrayName][index][searchKey] && obj[arrayName][index][searchKey] !== 'undefined' ? obj[arrayName][index][searchKey] : '';
              }
            }
          }
        }

        chunk = valueToInsert.toString() + splitChunk[1];
      }
      returnString += chunk;
    }
    return returnString;
  };

  that.get = function (args) {
    var key = args.path;
    var callback = args.callback;
    var forceReload = !!args.forceReload;
    var objectParam = args.object;
    var rbParam = args.rb;
    var template = getInternalTemplate(key);

    var html;

    if (template && !forceReload) {  // internal template found and OK to use cache
      html = returnTemplate({
        template: template,
        object: objectParam,
        rb: rbParam,
        callback: args.callback
      })
    } else {  // no internal template found or not OK to use cache
      // attempt to retrieve from DOM
      var matchingTemplateNode = null;
      jQuery(templateClassName).each(function () {
        if (jQuery(this).html() && (jQuery(this).attr("path") == key)) {
          matchingTemplateNode = this;
        }
      });
      if (matchingTemplateNode) { // inline template found in DOM
        template = setInternalTemplate(key, jQuery(matchingTemplateNode).html());
        html = returnTemplate({
          template: template,
          object: args.object,
          rb: rbParam,
          callback: args.callback
        });
      }
    }

    if (typeof args.callback === "function") { args.callback(html); }
    else { return html; }

  };

  that.loadMustacheMappings = function (args) {
    var args = args || { mappings: {} };

    if (args.mappings) {
      for (var key in args.mappings) {
        if (args.mappings.hasOwnProperty(key) && site.templates[args.mappings[key]]) {
          // These need to be mapped in both direction to handles partials
          templates[key] = site.templates[args.mappings[key]].content;
          templates[args.mappings[key]] = site.templates[args.mappings[key]].content;
        }
      }
    }
  }

  return that;

})();

generic.endeca.catalog = site.endeca.generic.Class.create({
	initialize: function( args ) {
        this.jsonResult = null;
        this.resultList = [];
        jQuery.extend( this, args || {} );
        
        if ( this.jsonResult ) { this.parseData(); }
	},
	
    parseData: function() {
        if ( this.jsonResult.AggrRecords ) {
            for ( var i = 0; i < this.jsonResult.AggrRecords.length; i++) {
                for ( var j = 0; j < this.jsonResult.AggrRecords[i].Records.length; j++ ) {
                    this._parseRecord( this.jsonResult.AggrRecords[i].Records[j] );
                }
            }
        } else if ( this.jsonResult.Records ) {
            for ( var i = 0; i < this.jsonResult.Records.length; i++ ) {
                this._parseRecord( this.jsonResult.Records[i] );
            }
        }
	}
});

site.endeca.catalog = generic.endeca.catalog;
generic.endeca.catalog.content = site.endeca.generic.Class.create( site.endeca.catalog, {	
	_parseRecord: function( record ) {
	    this.resultList.push({
	        "Properties": {
    	        "image": site.endeca.generic.rb('endeca').get('content.image_url'),
    	        "title": record.Properties.p_PROD_RGN_NAME,
    		    "description": record.Properties.p_DESCRIPTION,
    		    "link": record.Properties.p_url,
    		    "link_text": site.endeca.generic.rb('endeca').get('content.link_text'),
    		    "Zone": 'crawlData'
    		}
        });
	}
});


site.endeca.catalog.content = generic.endeca.catalog.content;

//
// EndecaCatalog class.
// This class parses the Endeca JSON data into category, product, and sku objects,
// and maintains lists (hashes) for each type.  You can then access each list by id,
// such as:
//		var catObj = this.categoryList[catid];
// The structure of the lists/objects is essentially the same as the "CatProdData" class
// of the CL-US project.  Thus, each product object also contains a "skus" list which
// is a list of references to sku objects (also on the skuList) which are skus for that
// product.  Thus, when you get a product object from the productList, that product
// object will have a list of all it's skus (at least the skus that were included
// in the data).
//
// There is a "rec_type" property on each data record that indicates either "product"
// or "content".  "product" record types have cat/prod/sku data, and are stored as such.
// "content" records are not products, and the data for these records is stored
// essentially verbatim on the "contentList" hash.  Typically content records are
// things like articles and videos on the site.
//
// NOTE - this class is intended only to be a convenient container for the data
// returned from an Endeca query.  Please do not add page/state data to this class.
//
// A discussion of the Endeca data format is at the end of this file.
//	

generic.endeca.catalog.product = site.endeca.generic.Class.create( site.endeca.catalog, {

	initialize: function(args) {
		
		this.categoryList = {};
		this.productList = {};
		this.skuList = {};
		
		this.parseOrderHi = 0;
		this.parseOrderLo = 0;
		
		this._super(args);
		
		this.resultList = this.getProducts();
	},
	
	_parseRecord: function( record ) {

    var propValue;
    var isPropValue = propValue === true;

    if ( record.Properties["rec_type"] === 'product' ) {
      var props = { 'c': {}, 'p': {}, 's': {} };

      props['p']['matched'] = 1;
      props['s']['matched'] = 0;

      for ( var prop in record.Properties ) {
        propValue = record.Properties[prop];

        if ( propValue && propValue !== "" && !isNaN( propValue ) ) {
          if ( propValue.match(/\./) ) {
            propValue = parseFloat(propValue);
          } else {
            propValue = parseInt(propValue);
          }
        }

        if ( prop.match(/_json/i) ) {
          prop = prop.replace(/_json/i, '');
          if (isPropValue) {
            propValue = JSON.parse(propValue);
          }
        }

        if ( prop.match(/^([a-z])_/) ) {
          props[RegExp.$1] = props[RegExp.$1] ? props[RegExp.$1] : [];
          props[RegExp.$1][prop.substr(2)] = propValue;
        }

        if ( prop === "DGraph.WhyDidItMatch" ) {
          props['s']['matchedOn'] = propValue;

          var matchedOnString = typeof propValue === "object" ? propValue.join() : propValue;

          if ( matchedOnString.match(/s_/) ) {
            props['p']['matched'] = 0;
            props['s']['matched'] = 1;
          }
        }
      }
      this.addProps( props['c'], props['p'], props['s'] );
    }
	},

	//from legacy
	addProps: function ( catProps, prodProps, skuProps, insert ) {
		// For now, i'm using id's, but we may want to use the "path", which is more specific...
		var catId = catProps.CATEGORY_ID;
		var prodId = prodProps.PRODUCT_ID;
		var skuId = skuProps.SKU_ID;
		
		// I'm paranoid - check for id's
		if ( !catId || !prodId || !skuId ) return;
		
		// Insert/update sku object
		var skuObj = this.skuList[skuId] || {};
		this.skuList[skuId] = jQuery.extend(skuObj,skuProps);
		
		// If existing product record, use that and update.
		// Else create new one.
		var prodObj = this.productList[prodId] || { parseOrder: ++this.parseOrderHi };
		
		// If inserting, parse order should be negative.
		if ( insert && prodObj.parseOrder > 0 ) {
			prodObj.parseOrder = --this.parseOrderLo;
		}
		
		prodObj = jQuery.extend(prodObj,prodProps);
		if ( !prodObj.skus )
			prodObj.skus = [];
		if ( !prodObj.skuList )
			prodObj.skuList = {};
		// Make sure each sku is listed only once per product
		if ( !prodObj.skuList[skuId] ) 
			prodObj.skus.push(skuObj);
		prodObj.skuList[skuId] = skuObj;
		this.productList[prodId] = prodObj;
		
		skuObj.product = prodObj;
		
		var catObj = this.categoryList[catId] || {};
		catObj = jQuery.extend(catObj,catProps);
		if ( !catObj.prods ) catObj.prods = [];
		catObj.prods.push(prodObj);		
		this.categoryList[catId] = catObj;
	},
	
	
	// Return an array of product objects, sorted by parseOrder
	getProducts: function() {
	  function sortByParseOrder( a, b ) {
      if ( a.parseOrder > b.parseOrder ) {
        return 1;
      } else if ( a.parseOrder < b.parseOrder ) {
        return -1;
      }
      return 0;
		}
		
		function sortByDisplayOrder( a, b ) {
	    if ( a.DISPLAY_ORDER > b.DISPLAY_ORDER ) {
        return 1;
      }
	    else if ( a.DISPLAY_ORDER < b.DISPLAY_ORDER ) {
        return -1;
      }
      return 0;
		}
		
		var prods = [];
	    
    for ( var prodId in this.productList ) {
        this.productList[prodId].skus.sort(sortByDisplayOrder);
        prods.push( this.productList[prodId] );
    }

    prods.sort(sortByParseOrder);
		
		return prods;
	},
	
	// Return an array of sku objects
	getSkus: function() {
		var skus = [];
		for ( var sku in this.skuList ) {
		    skus.push( this.skuList[sku] );
		}
		return skus;
	},
	
	getCategory: function(catid) {
		var catObj = ( this.categoryList ? this.categoryList[catid] : null );
		return catObj;
	},
	
	getProduct: function(prodid) {
		var prodObj = ( this.productList ? this.productList[prodid] : null );
		return prodObj;
	},
	
	getSku: function(skuid) {
		var skuObj = ( this.skuList ? this.skuList[skuid] : null );
		return skuObj;
	}
	
});

site.endeca.catalog.product = generic.endeca.catalog.product;


/*
The Endeca data arrives as a JSON hash.  Each record in the hash is a complete sku record,
including all the category, product, and sku properties for that sku.

When we request the data, we request a rollup on PRODUCT_ID.  This groups the
data by product.  The "parent" product is called the "Aggregate Record" (in Endeca-land).
Thus, in the hash, the first sku is the "AggrRecord" (which is a sku representative of
the rolled-up product record), and all the other skus (if any) then follow that record.

Because Endeca flattens the entire database into sku-specific records, before sending
the data to Endeca, we pre-pend each property name with a "c_", "p_", or "s_", to indicate
if that property is a Category, Product, or Sku property (respectively). Then, when
we parse out each Endeca record, we can split the record into category, product, and
sku properties.  As we parse the data, we create a category, product, and sku object
for each sku, and then merge that object into the list of cats/prods/skus in our
EndecaCatalog class.

Note that because a product often has multiple skus, we will see the same product id
more than once as we process those skus.  Thus, we want to "update" the product record
in our list with the new sku info, and not just "add" the product record (since that would
potentially create duplicate product records).  A similar scenario may occur if a
product belongs to more than one category.

Here is a very simplified synopsys of the Endeca data JSON format:

	AggrRecords: [
		{	-- new product
			Records: [
				{
					Properties: {
						c_*
						p_*
						s_*
					}
				}
				{
					Properties: {
						c_*
						p_*
						s_*
					}
				}
			]
		}
		{	-- new product
			Records: [
				{
					Properties: {
						c_*
						p_*
						s_*
					}
				}
				{
					Properties: {
						c_*
						p_*
						s_*
					}
				}
			]
		}
	]
*/
	

/*
    Base endeca control class.
    
    This is the base class that will control all instances of endeca. All instances of endeca will have a control
    class that inherits from this base class.

*/

generic.endeca.control = site.endeca.generic.Class.create({
    initialize: function( args ) {            
        this.configuration = args || site.endeca.configuration;
        
        this.queryString = site.endeca.generic.env.query('qs') || "";
        this.searchTerm = site.endeca.generic.env.query('search') || "";
        
        this.hasResults = false;
        this.hasSearched = 0;
        this.wildcardSearch = false;
        
        this.customClasses = {};
        this.results = {};
        this.queries = {};
        this.catalogs = {};
        this.nodes = {};
        
        if ( this.configuration.mustacheMappings ) { this.loadMustacheMappings(); }
        if ( this.configuration.queries ) { this.generateQueries(); }
        if ( this.configuration.results ) { this.generateResults(); }
        if ( this.configuration.nodes ) { this.generateNodes(); }
        
        if ( this.configuration.coremetricsEnabled ) {
            site.endeca.coremetrics.initialize({ enabled: true });
        }

        if ( this.configuration.omnitureEnabled ) {
            if (site.endeca.omniture) {
                site.endeca.omniture.initialize({ enabled: true });
            }
        }
        
        if ( this.hasAnalyticsIntegrated() && !this.isTypeahead() ) {
            if ( site && site.track ) {
                site.track.disableDefaultPageView();
            }
        }
        
    },
    
    loadMustacheMappings: function () {
        if ( this.configuration.mustacheMappings ) {
            site.endeca.generic.template.loadMustacheMappings({ mappings: this.configuration.mustacheMappings });
        }
    },
    
    generateQueries: function() {
        /*
            Take the information provided from the configuration and instantiate all of the necessary queries for this
            class. Queries will be accessible from this.queries[queryName].
            
        */
        
        for ( var query in this.configuration.queries ) {
            this.queries[query] = new site.endeca.query( jQuery.extend(
                { callbackCompleted: site.endeca.helpers.func.bind( this.searchCompleted, this ) }, this.configuration.query,
                this.configuration.queries[query] || {}
            ));
        }
    },
    
    generateResults: function() {
        /*
            Create custom classes for each of the results configuration objects.
        */
        
        for ( var resultsName in this.configuration.results ) {
            // Allow for optional childClass setting in configuration
            this.configuration.results[resultsName].childClass = this.configuration.results[resultsName].childClass || "";
            
            /* 
                Determine which mixins we should be using for this custom class:
                
                1. Use the mixinKey provided in the configuration for this class.
                2. Remove 'site.endeca' from the childClass string and use the remainder as the mixinKey: 
                    childClass = 'site.endeca.results.products', mixinKey = 'results.products'
                3. Remove 'site.endeca' from the baseClass string and add on the resultName as the mixinKey
                    baseClass = 'site.endeca.results', resultsName = 'products', mixinKey = 'results.products'
                4. Remove 'site.endeca' from the baseClass string as the mixinKey
                    baseClass = 'site.endeca.results', mixinKey = 'results'
                
            */
            
            var mixins =    this.configuration.mixins[this.configuration.results[resultsName].mixinKey] || 
                            this.configuration.mixins[this.configuration.results[resultsName].childClass.replace(/site\.endeca\./, '')] ||
                            this.configuration.mixins[this.configuration.results[resultsName].baseClass.replace(/site\.endeca\./, '') + '.' + resultsName] ||
                            this.configuration.mixins[this.configuration.results[resultsName].baseClass.replace(/site\.endeca\./, '')];

            var baseClass = eval(this.configuration.results[resultsName].baseClass);
            
            // Use childClass provided in configuration or
            // Use resultsName to retrieve childClass from baseClass or
            // use an empty object
            var childClass = eval(this.configuration.results[resultsName].childClass) || baseClass[resultsName] || {};
            
            // Create a custom class created from the baseClass, appropriate mixins, and the childClass
            this.customClasses[resultsName] = site.endeca.generic.Class.create( site.endeca.generic.Class.mixin( baseClass, mixins ), childClass );
            
            // Instantiate custom class in this.results[resultsName]
            // Pass in the mixins from configuration for use in result(s) generation
            // Pass in any configuration settings specified in the configuration file for this class
            // Pass in any instanceArgs specified in the configuration file for this class
            this.results[resultsName] = new this.customClasses[resultsName]( jQuery.extend( {}, { mixins: this.configuration.mixins, configuration: this.configuration.results[resultsName].configuration || {} }, this.configuration.results[resultsName].instanceArgs || {} ) );
        }
    },
    
    generateNodes: function() {
        for ( var nodeName in this.configuration.nodes ) {
            this.nodes[nodeName] = this.configuration.nodes[nodeName];
        }
    },
    
    search: function( args ) {
        var args = args || {
            searchTerm: null,
            queryString: null
        };
        
        this.hasSearched++;
        
        this.showLoading();
        this.resetQueries();
        
        // Get searchTerm from queryString here in order to synchronize all queries on the same search term
        var queryString = args.queryString || this.queryString || '';
        var searchTerm = queryString ? site.endeca.helpers.string.toQueryParams( queryString )['Ntt'] : ( args.searchTerm || this.searchTerm || '' );
        
        for ( var query in this.queries ) {
            this.queries[query].searchTerm = searchTerm;
            this.queries[query].queryString = this.queries[query].noQueryString ? '' : queryString;
            this.queries[query].prepare();
            this.queries[query].execute();
            
            this.searchTerm = this.queries[query].searchTerm;
        }
    },
    
    searchCompleted: function( args ) {
        if ( this.queriesCompleted() ) {
            this.resetResults();
            
            for ( var query in this.queries ) {
    		    this.catalogs[query] = new site.endeca.catalog[query]({ jsonResult: this.queries[query].jsonResult });
    		}
    		
            this.meta = new site.endeca.meta({ query: this.queries.product, jsonResult: this.queries.product.jsonResult, searchKey: this.queries.product.searchKey, configuration: { followRedirects: this.configuration.followRedirects, sorting: this.configuration.sorting, contentzones: this.configuration.contentzones } });
            
            if ( this.meta.redirecting ) { 
                // fire redirection event
                if ( this.hasAnalyticsIntegrated() && site && site.elcEvents ) {
                    site.elcEvents.dispatch('track:searchRedirect', this);
                }
                return false; 
            }
            
            this.hideLoading();
           
            // fire search loaded event - delayed to give utag a chance to load
            if ( this.hasAnalyticsIntegrated() && !this.wildcardSearch && site && site.elcEvents ) {
                var that = this;
                if ( this.isTypeahead() ) {
                    site.elcEvents.dispatch('track:searchPredicted', that);
                    site.elcEvents.addListener('track:ready', function() {
                        site.elcEvents.dispatch('track:searchTypeaheadLoaded', that);
                    });
                } else {
                    site.elcEvents.addListener('track:ready', function() {
                        site.elcEvents.dispatch('track:searchPageLoaded', that);
                    });
                }
            }
            
            return true;
        }
        
        return false;
    },
    
    
    
    queriesCompleted: function() {
        for ( var query in this.queries ) {
            if ( !this.queries[query].completed ) { return false; }
        }
        return true;
    },
    
    processCoremetrics: function( args ) {
        var args = args || {
            pageView: true
        };
        // this should be called from your searchCompleted in your instance's control subclass.
        if ( this.configuration.coremetricsEnabled ) { 
            site.endeca.coremetrics.reset();
            site.endeca.coremetrics.pageView = args.pageView;
            site.endeca.coremetrics.productCount = this.meta.searchInfo.totalProductRecords;
            site.endeca.coremetrics.contentCount = this.meta.searchInfo.totalContentRecords;
            site.endeca.coremetrics.searchTerm = this.meta.searchInfo.correctedTerms && this.meta.searchInfo.correctedTerms.length ? this.meta.searchInfo.correctedTerms[0] : this.queries.product.parsedSearchTerm();
            site.endeca.coremetrics.wildcardSearch = this.wildcardSearch;
            site.endeca.coremetrics.numberOfPages = this.meta.pagination ? this.meta.pagination.numberOfPages : 1;
            site.endeca.coremetrics.currentPage = this.meta.pagination ? this.meta.pagination.numberOfCurrentPage : 1;
            if ( this.meta.dimensions.breadcrumbs ) {
                for ( var i = 0; i < this.meta.dimensions.breadcrumbs.length; i++ ) {
                    for ( var j = 0; j < this.meta.dimensions.breadcrumbs[i]["Dimension Values"].length; j++ ) {
                        site.endeca.coremetrics.addRefinement({
                            dimensionName: this.meta.dimensions.breadcrumbs[i]["Dimension Name"],
                            refinement: this.meta.dimensions.breadcrumbs[i]["Dimension Values"][j]["Dim Value Name"]
                        });
                    }
                }
            }
            site.endeca.coremetrics.setPageView(); 
        }
    },

    processOmniture: function() {
        // this should be called from your searchCompleted in your instance's control subclass.
        if ( this.configuration.omnitureEnabled ) {
            site.endeca.omniture.reset();

            // Will use tms_page_data instead of site.endeca because that brings this data under the helm of the Generic
            // Data Dictionary for tagging.
            site.endeca.omniture.productCount = this.meta.searchInfo.totalProductRecords;
            site.endeca.omniture.contentCount = this.meta.searchInfo.totalContentRecords;
            site.endeca.omniture.searchTerm = this.meta.searchInfo.correctedTerms && this.meta.searchInfo.correctedTerms.length ? this.meta.searchInfo.correctedTerms[0] : this.queries.product.parsedSearchTerm();
            site.endeca.omniture.numberOfPages = this.meta.pagination ? this.meta.pagination.numberOfPages : 1;
            site.endeca.omniture.currentPage = this.meta.pagination ? this.meta.pagination.numberOfCurrentPage : 1;
            
            var searchType = this.configuration.searchType || this.queries.product.searchKey;
            if ( searchType ) {
                site.endeca.omniture.searchType = searchType;
            }

            if (searchType == "all") {
               if ( this.meta.dimensions.breadcrumbs ) {
                  var lastBC = this.meta.dimensions.breadcrumbs[ this.meta.dimensions.breadcrumbs.length - 1 ];
                  var lastBCVal = lastBC["Dimension Values"][ lastBC["Dimension Values"].length - 1 ];
                  site.endeca.omniture.refineSearch( lastBCVal["Dim Value Name"] );
               } else {
                  site.endeca.omniture.searchResults();
               }
           }
        }
    },
    
    //somewhat fragile, but can be overridden at the brand level if needed.
    isTypeahead: function() {
        if ( this.configuration.minSearchLength ) {
            return 1;
        } else {
            return 0;
        }
    },
    
    hasAnalyticsIntegrated: function() {
        if ( Drupal && Drupal.settings && Drupal.settings.analytics ) {
            return Drupal.settings.analytics.analytics_integrated;
        } else {
            return 0;
        }
    },
    
    showLoading: function() {
        if ( this.nodes.loading ) {
            this.nodes.loading.show();
        }
    },
    
    hideLoading: function() {
        if ( this.nodes.loading ) {
            this.nodes.loading.hide();
        }
    },
    
    displayResults: function() {
        if ( this.hasResults ) {
            if ( this.results.bestsellers ) { this.results.bestsellers.hide(); }
            if ( this.results.content ) { this.results.content.show(); }
            if ( this.nodes.resultsContainer ) { this.nodes.resultsContainer.show(); }
            if ( this.nodes.noResultsContainer ) { this.nodes.noResultsContainer.hide(); }
	        this.processCoremetrics();
                this.processOmniture();
            this.wildcardSearch = false;
            return true;
        } else {
            if ( this.wildcardSearch ) {
                if ( this.nodes.resultsContainer ) { this.nodes.resultsContainer.hide(); }
                if ( this.nodes.noResultsContainer ) { this.nodes.noResultsContainer.show(); }
                if ( this.results.content ) { 
                    if ( this.configuration.noResultsContentZone ) {                
                        this.results.content.contentzones = this.configuration.noResultsContentZone;
                        this.results.content.resultData = this.meta.supplementalContent;
                        this.results.content.displayResults();
                        this.results.content.show();
                    } else {
                        this.results.content.hide(); 
                    }
                }
                if ( this.results.bestsellers ) {
            	    this.results.bestsellers.displayResults();
            	    this.results.bestsellers.show();
                }
                
	            this.processCoremetrics();
                    this.processOmniture();
                this.wildcardSearch = false;
                return true;
            } else {
                this.wildcardSearch = true;
                this.search({ searchTerm: this.searchTerm + '*' });
                return false;
            }
        }
    },  
         
    
    resetQueries: function() {
        for ( var query in this.queries ) {
            this.queries[query].reset();
        }
    },
    
    resetResults: function() {  
        this.hasResults = false;
        for ( var resultsName in this.results ) {
            this.results[resultsName].reset();
        }
    }
});

site.endeca.control = generic.endeca.control;

/**

Is this a GENERIC file - if values need to be modified, they either need to be passed in from control.js OR this file can be extended at the instance level (see example in sites/clinique/na/js/pc/site/endeca/instances/foundation_finder/option/coremetrics.js)

**/

generic.endeca.coremetrics = {
    enabled: false,
    category_id: "search",
    page_id: "Search Results",
    productCount: 0,
    contentCount: 0,
    searchTerm: null,
    refinementsList: [],
    numberOfPages: 1,
    currentPage: 1,
    pageView: true,
    dimensionNameMap: {
        "Skin Type" : "Typ",
        "Skin Tone" : "Ton"
    },
    wildcardSearch: false,
    
    initialize: function( args ) {
        jQuery.extend( this, args );
    },
    
    addRefinement: function ( args ) {
        var args = args || {};
        if ( args.dimensionName && args.refinement ) {
            var dimensionName;
            if ( this.dimensionNameMap[args.dimensionName] ) {
                dimensionName = this.dimensionNameMap[args.dimensionName];
            } else {
                var dimensionNameWords = args.dimensionName.split(' ');
                dimensionName = dimensionNameWords.shift().substr(0,3);
                for ( var i = 0; i < dimensionNameWords.length; i++ ) {
                    dimensionName += dimensionNameWords[i].charAt(0);
                }
            }
            
            this.refinementsList.push( dimensionName + ':' + args.refinement );
        }
    },
    
    setPageView: function () {
        if ( this.pageView ) {            
            var PAGE_ID = this.page_id + " " + this.currentPage;
            var CATID = this.category_id;
            var KEYWORDS = this.searchTerm;
            var RESULTS = this.contentCount + this.productCount;
            var FILTERLIST = this.refinementsList.join(' > ');
            
            if ( FILTERLIST ){
                PAGE_ID = 'Search Results Filtered ' + this.currentPage;
            }
            
            if ( this.contentCount > 0 && this.productCount == 0 ) {
                KEYWORDS = '*' + KEYWORDS;
            }
            
            if ( typeof cmCreatePageviewTag == 'function' ) {
                cmCreatePageviewTag( PAGE_ID, KEYWORDS, CATID, RESULTS.toString(), FILTERLIST );
            }
            
            if ( this.wildcardSearch ) {
                if ( typeof cmCreateConversionEventTag == 'function' ) {
        	        cmCreateConversionEventTag("RESULTS PAGE", 1, "ENDECA WILDCARD SEARCH", 1);
        	    }
            } else {
                if ( typeof cmCreateConversionEventTag == 'function' ) {
        	        cmCreateConversionEventTag("RESULTS PAGE", 1, "NO ENDECA WILDCARD SEARCH", 1);
        	    }
            }
        }
    },
    
    contentClick: function() {
        if ( typeof cmCreatePageElementTag == 'function' ) {
            cmCreatePageElementTag("CONTENT", "SEARCH DROPDOWN");
        }
    },
    
    productClick: function() {
        if (typeof cmCreatePageElementTag === 'function') {
          cmCreatePageElementTag("PRODUCTS", "SEARCH DROPDOWN");
        }

        if ( this.wildcardSearch ) {
            if ( typeof cmCreateConversionEventTag == 'function' ) {
	            cmCreateConversionEventTag("SEARCH DROPDOWN", 1, "ENDECA WILDCARD SEARCH", 1);
	        }
        } else {
            if ( typeof cmCreateConversionEventTag == 'function' ) {
	            cmCreateConversionEventTag("SEARCH DROPDOWN", 1, "NO ENDECA WILDCARD SEARCH", 1);
	        }
        }
    },
    
    seeAllClick: function() {
        if ( typeof cmCreatePageElementTag == 'function' ) {
            cmCreatePageElementTag("SEE ALL","SEARCH DROPDOWN");
        }
    },
    
    reset: function() {
        this.refinementsList = [];
        this.pageView = true;
    }
};

site.endeca.coremetrics = generic.endeca.coremetrics;

var tms_page_data = tms_page_data || {};

site.endeca.omniture = {
    enabled: false,
    page_id: "Search Results",
    productCount: 0,
    contentCount: 0,
    searchTerm: null,
    searchType: "Regular",
    refinementsList: [],
    numberOfPages: 1,
    currentPage: 1,
    
    initialize: function( args ) {
        jQuery.extend( this, args );
    },
    
    searchResults: function () {
        var PAGE_ID = this.page_id + " " + this.currentPage;
        var KEYWORDS = this.searchTerm;
        var SEARCH_TYPE = this.searchType;
        var RESULTS = this.contentCount + this.productCount;
        
        if ( this.contentCount > 0 && this.productCount == 0 ) {
            KEYWORDS = '*' + KEYWORDS;
        }
        
        omnidata = [KEYWORDS, this.contentCount, this.productCount, PAGE_ID, SEARCH_TYPE];
        if(typeof tms_page_data.tms_page_info != "undefined") {
            tms_page_data.tms_page_info['SEARCH'] = omnidata; 
        } else {
            tms_page_data['SEARCH'] = omnidata;
        }
            jQuery(window).trigger("OMNISEARCH", [omnidata]);
        // console.log("SC PAGE VIEW");
    },
    
    refineSearch: function( refinementName ) {
        omnidata = [refinementName, this.productCount];
        if(typeof tms_page_data.tms_page_info != "undefined") {
            tms_page_data.tms_page_info['FILTERSEARCH'] = omnidata;
        } else {
            tms_page_data['FILTERSEARCH'] = omnidata;
        }
        jQuery(window).trigger("FILTERSEARCH",[omnidata]);
        // console.log("SEARCH FILTER EVENT",omnidata);
    },
    
    contentClick: function() {
        
    },
    
    productClick: function() {
        var PAGE_ID = this.page_id + " " + this.currentPage;
        var KEYWORDS = this.searchTerm;
        var SEARCH_TYPE = this.searchType;
        var RESULTS = this.contentCount + this.productCount;

        if ( this.contentCount > 0 && this.productCount == 0 ) {
            KEYWORDS = '*' + KEYWORDS;
        }

        omnidata = [KEYWORDS, this.contentCount, this.productCount, PAGE_ID, SEARCH_TYPE];
        if(typeof tms_page_data.tms_page_info != "undefined") {
            tms_page_data.tms_page_info['TYPEAHEAD'] = omnidata;
        } else {
            tms_page_data['TYPEAHEAD'] = omnidata;
        }
        jQuery(window).trigger("OMNISEARCH", [omnidata]); 
        //console.log("product click",omnidata);
    },
    
    seeAllClick: function() {
        jQuery(window).trigger("SEARCHALLCLK"); 
    },
    
    reset: function() {
        this.refinementsList = [];
    }
};

